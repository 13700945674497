import React, { useState } from 'react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4">
      <button
        className="flex justify-between items-center w-full text-left font-semibold py-2 px-4 bg-purple-800 hover:bg-purple-700 rounded-lg transition duration-300"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{question}</span>
        <span>{isOpen ? '-' : '+'}</span>
      </button>
      {isOpen && (
        <div className="mt-2 py-2 px-4 bg-purple-900 rounded-lg">
          {answer}
        </div>
      )}
    </div>
  );
};

const FAQs = () => {
  const faqs = [
    {
      question: "What is NeXterChat API?",
      answer: "NeXterChat API is a powerful compiler API service that provides developers with tools for code execution, optimization, and analysis across multiple programming languages."
    },
    {
      question: "Which programming languages does NeXterChat API support?",
      answer: "NeXterChat API supports a wide range of popular programming languages including but not limited to Python, JavaScript, Java, C++, and Ruby. We're constantly adding support for more languages."
    },
    {
      question: "How can I get started with NeXterChat API?",
      answer: "You can get started by signing up for a free account on our website. Once registered, you'll receive an API key that you can use to make requests to our service."
    },
    {
      question: "What are the pricing options for NeXterChat API?",
      answer: "We offer flexible pricing tiers based on usage, including a free tier for small projects and hobbyists. For detailed pricing information, please visit our Pricing page."
    },
    {
      question: "Is NeXterChat API secure?",
      answer: "Yes, security is our top priority. We use industry-standard encryption for all API calls and implement strict sandboxing for code execution to ensure the safety and privacy of your code."
    },
    {
      question: "Can I use NeXterChat API for commercial projects?",
      answer: "Absolutely! Our service is designed to scale from personal projects to enterprise-level applications. We offer business plans with additional features and support for commercial use."
    },
    {
      question: "Do you offer custom solutions or integrations?",
      answer: "Yes, we provide custom solutions and integrations for businesses with specific needs. Please contact our sales team for more information on tailored services."
    },
    {
      question: "What kind of support do you provide?",
      answer: "We offer comprehensive documentation, code samples, and community forums for all users. Paid plans include email support, and our enterprise plans come with dedicated account managers and priority support."
    },
    {
      question: "How does NeXterChat API handle rate limiting?",
      answer: "Rate limits vary by plan. We provide clear documentation on rate limits for each tier and offer options to increase these limits for high-volume users."
    },
    {
      question: "Can I test NeXterChat API before committing to a paid plan?",
      answer: "Yes, we offer a free tier that allows you to explore our API's capabilities. Additionally, we provide a sandbox environment for testing and development purposes."
    }
  ];

  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-4xl font-bold mb-8 text-center text-gradient bg-gradient-to-r from-pink-500 to-purple-600 bg-clip-text text-transparent">Frequently Asked Questions</h1>
      <div className="max-w-3xl mx-auto">
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQs;