import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignUpForm from '../components/SignUpForm';
import FullPageLoader from '../components/FullPageLoader';
import API from '../api/config';
import { useAuth } from '../auth/AuthContext';

const SignUp = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { login } = useAuth();

  const handleSignUp = async (userData) => {
    setIsLoading(true);
    setError('');
    try {
      const response = await API.post('/users/register', userData);
      if (response.data && response.data.token) {
        localStorage.setItem('authToken', response.data.token);
        // Log the user in after successful registration
        await login(userData.email, userData.password);
        // Redirect to dashboard after successful sign-up and login
        navigate('/dashboard');
      } else {
        throw new Error('Registration failed: No token received');
      }
    } catch (err) {
      console.error('Registration error:', err);
      setError(err.response?.data?.message || 'An error occurred during registration');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 via-purple-800 to-pink-800 flex justify-center items-center">
      <div className="smooth-landing">
        <SignUpForm onSubmit={handleSignUp} error={error} />
      </div>
    </div>
  );
};

export default SignUp;