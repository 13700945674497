import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext'; // Adjust if needed
import API from '../api/config'; // Adjust if needed

const PricingTier = ({ name, price, credits, duration, ctaText, planId }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleClick = async () => {
    if (!user) {
      // If user is not logged in, navigate to signup page
      navigate('/signup', { state: { redirectPlan: planId } });
    } else {
      // If user is logged in, initiate payment process
      try {
        const response = await API.post(`/subscription/create-razorpay-order/${planId}`);
        const { id: orderId, amount } = response.data;

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID,
          amount: amount,
          currency: "INR",
          name: "NeXterChat API",
          description: `Subscription to ${name}`,
          order_id: orderId,
          handler: async function (response) {
            try {
              const verificationResponse = await API.post('/subscription/verify-razorpay-payment', {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                plan_id: planId
              });

              if (verificationResponse.data.success) {
                console.log('Subscription successful');
                // Handle successful subscription (e.g., show success message, update user state)
              } else {
                console.error('Subscription failed');
                // Handle failed subscription
              }
            } catch (error) {
              console.error('Payment verification failed:', error);
              // Handle payment verification failure
            }
          },
          prefill: {
            email: user.email,
            contact: user.phone // Assuming you have the user's phone number
          },
          theme: {
            color: "#8B5CF6"
          }
        };

        const razorpay = new window.Razorpay(options);
        razorpay.open();
      } catch (error) {
        console.error('Error initiating payment:', error);
        // Handle error (e.g., show an error message to the user)
      }
    }
  };

  return (
    <div className="bg-purple-900 p-6 rounded-lg shadow-lg flex flex-col h-full transition-all duration-300 hover:shadow-xl hover:scale-105">
      <div className="flex justify-between items-start mb-6">
        <h3 className="text-2xl font-bold">{name}</h3>
        <div className="text-right">
          <p className="text-4xl ">${price}</p>
          <p className="text-sm">{duration}</p>
        </div>
      </div>
      <ul className="mb-8 text-sm flex-grow">
        <li className="mb-3 flex items-center">
          <svg className="w-5 h-5 mr-2 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
          {credits}
        </li>
        <li className="mb-3 flex items-center">
          <svg className="w-5 h-5 mr-2 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
          {price === 0 ? 'Basic support' : 'Priority support'}
        </li>
        <li className="mb-3 flex items-center">
          <svg className="w-5 h-5 mr-2 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
          {price === 0 ? 'Basic features' : 'Advanced features'}
        </li>
      </ul>
      <div className="mt-auto">
        <button 
          onClick={handleClick}
          className="block w-full bg-[#8B5CF6] text-white px-4 py-3 rounded-full font-semibold hover:bg-[#7C3AED] transition duration-300 text-center"
        >
          {ctaText}
        </button>
      </div>
    </div>
  );
};

const Pricing = () => {
  const location = useLocation();
  const pricingRef = useRef(null);

  useEffect(() => {
    if (location.hash === '#pricing' && pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  const pricingTiers = [
    { name: 'Free Plan', price: 0, credits: '50 credits/day', duration: 'Free forever', ctaText: 'Start for free', planId: 'free-plan' },
    { name: 'Monthly Plan', price: 4.99, credits: '45,000 credits/month', duration: 'monthly', ctaText: 'Get Monthly', planId: 'monthly-plan' },
    { name: 'Three Months Plan', price: 12.99, credits: '2,000 credits/day', duration: 'quarterly', ctaText: 'Get Quarterly', planId: 'three-months-plan' },
    { name: 'Six Months Plan', price: 19.99, credits: '3,000 credits/day', duration: 'semiannual', ctaText: 'Get Semiannual', planId: 'six-months-plan' },
    { name: 'Yearly Plan', price: 35.99, credits: 'Unlimited credits', duration: 'annual', ctaText: 'Get Annual', planId: 'yearly-plan' }
  ];

  return (
    <div ref={pricingRef} className="bg-purple-950 py-16">
      <div className="container mx-auto px-4 max-w-5xl">
        <h1 className="text-4xl font-bold mb-12 text-center text-white">
          Discover Our API Plans and Pricing
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {pricingTiers.slice(0, 3).map((tier, index) => (
            <PricingTier key={index} {...tier} />
          ))}
        </div>
        <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6 md:w-2/3 mx-auto">
          {pricingTiers.slice(3).map((tier, index) => (
            <PricingTier key={index + 3} {...tier} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;