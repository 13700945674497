import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../api/config';
import { useAuth } from '../../auth/AuthContext';
import { User, LayoutDashboard, Lock, Bell, ChevronDown, CreditCard, HelpCircle, Activity, Zap } from 'lucide-react';
import DashboardContent from './Content';
import ProfileContent from './ProfileContent';
import SecurityContent from './SecurityContent';
import BillingContent from './BillingContent';

const Dashboard = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  
  const [transactions, setTransactions] = useState([]);
  const [balance, setBalance] = useState(0);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  const [activeTab, setActiveTab] = useState('dashboard');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({
    status: 'Free',
    plan: 'Free',
    creditsPerDay: 50,
    remainingCredits: 50,
  });

  const menuItems = [
    { id: 'dashboard', icon: LayoutDashboard, label: 'Dashboard' },
    { id: 'profile', icon: User, label: 'Profile' },
    { id: 'security', icon: Lock, label: 'Security' },
    { id: 'billing', icon: CreditCard, label: 'Billing' },
    { id: 'notifications', icon: Bell, label: 'Notifications' },
    { id: 'integrations', icon: Zap, label: 'Integrations' },
    { id: 'activity', icon: Activity, label: 'Activity Log' },
  ];

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const headers = { Authorization: `Bearer ${token}` };

      const [userResponse, subscriptionResponse, transactionsResponse] = await Promise.all([
        API.get('/users/profile', { headers }),
        API.get('/subscription/status', { headers }),
        API.get('/users/transactions', { headers })
      ]);

      setBalance(userResponse.data.balance || 0);
      setSubscriptionData(subscriptionResponse.data);
      setTransactions(transactionsResponse.data);
    } catch (err) {
      console.error('Error fetching user data:', err);
      if (err.response?.status === 404) {
        setError('User data not found. Please try logging in again.');
      } else {
        setError(err.response?.data?.message || 'Failed to fetch user data. Please try again later.');
      }
      if (err.response?.status === 401) {
        logout();
        navigate('/login');
      }
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserData();
      const intervalId = setInterval(fetchUserData, 5000);
      return () => clearInterval(intervalId);
    }
  }, [user, logout, navigate]);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return <DashboardContent 
          user={user} 
          balance={balance} 
          subscriptionData={subscriptionData} 
          transactions={transactions} 
        />;
      case 'profile':
        return <ProfileContent />;
      case 'security':
        return <SecurityContent />;
      case 'billing':
        return <BillingContent />;
      default:
        return <div>Content for {activeTab}</div>;
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  const userInitial = user.email ? user.email[0].toUpperCase() : 'U';

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <header className="bg-[#2c183f] text-white shadow-sm z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4">
            <div className="flex items-center">
              <a href="/" className="text-2xl font-bold hover:text-indigo-300 transition-colors duration-200">
                Go to Home
              </a>
            </div>
            <div className="relative">
              <button 
                className="flex items-center text-white focus:outline-none"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <div className="w-8 h-8 bg-indigo-500 rounded-full flex items-center justify-center mr-2">
                  {userInitial}
                </div>
                <ChevronDown className="w-4 h-4" />
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-500 hover:text-white">Your Profile</a>
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-500 hover:text-white">Settings</a>
                  <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-indigo-500 hover:text-white">Sign out</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      <div className="flex flex-1 overflow-hidden">
        <div className="w-64 bg-white shadow-md overflow-y-auto">
          <nav className="mt-5 px-2">
            {menuItems.map((item) => (
              <button
                key={item.id}
                className={`flex items-center w-full px-4 py-3 text-left mb-1 rounded-md transition-colors duration-150 ${
                  activeTab === item.id
                    ? 'bg-indigo-100 text-indigo-600'
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
                onClick={() => setActiveTab(item.id)}
              >
                <item.icon className="w-5 h-5 mr-3" />
                {item.label}
              </button>
            ))}
          </nav>
          <div className="px-4 py-4 mt-auto">
            <button className="flex items-center w-full px-4 py-2 text-left rounded-md text-gray-600 hover:bg-gray-100 transition-colors duration-150">
              <HelpCircle className="w-5 h-5 mr-3" />
              Help & Support
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-8 bg-gray-50">
          {error && <div className="error-message">{error}</div>}
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;