import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const LoginForm = ({ onSubmit }) => {
  const [credentials, setCredentials] = useState({ emailOrUsername: '', password: '' });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onSubmit(credentials);
    } catch (err) {
      setError(err.message || 'An error occurred during login');
    }
  };

  return (
    <div className="form-container bg-gradient-to-br from-purple-900 via-purple-800 to-pink-800 p-8 rounded-lg shadow-lg max-w-md w-full">
      <h2 className="text-3xl font-bold text-white mb-6 text-center">Login</h2>
      {error && <p className="text-red-500 text-center mb-4">{error}</p>}
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="emailOrUsername" className="text-sm font-medium text-gray-300 block mb-2">Email or Username</label>
          <input 
            type="text" 
            name="emailOrUsername" 
            id="emailOrUsername" 
            className="bg-gray-700 text-white w-full px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600" 
            onChange={handleChange}
            value={credentials.emailOrUsername}
            required
          />
        </div>
        <div>
          <label htmlFor="password" className="text-sm font-medium text-gray-300 block mb-2">Password</label>
          <input 
            type="password" 
            name="password" 
            id="password" 
            className="bg-gray-700 text-white w-full px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600" 
            onChange={handleChange}
            value={credentials.password}
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <Link to="/forgot-password" className="text-sm text-purple-400 hover:text-purple-300">Forgot Password?</Link>
        </div>
        <button 
          type="submit" 
          className="w-full bg-pink-600 text-white py-2 px-4 rounded-md hover:bg-pink-700 transition duration-300 flex items-center justify-center"
        >
          Sign in
        </button>
      </form>
      <p className="mt-8 text-center text-sm text-gray-300">
        Don't have an account?
        <Link to="/signup" className="font-medium text-pink-400 hover:text-pink-300 ml-1">Sign up</Link>
      </p>
    </div>
  );
};

export default LoginForm;