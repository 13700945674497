import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaBars, FaTimes } from 'react-icons/fa'; // Import the user icon
import logo from '../assets/logo.png';
import { useAuth } from '../auth/AuthContext';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/'); // Redirect to home page after logout
    setIsMenuOpen(false);
  };

  const closeMenu = () => setIsMenuOpen(false);

  return (
    <>
      <nav className="bg-[#2c183f] text-white fixed top-0 left-0 right-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex-shrink-0 flex items-center">
              <img src={logo} alt="NeXterChat API Logo" className="w-8 h-8 mr-2" />
              <span className="font-bold text-lg sm:text-xl">NeXterChat API</span>
            </div>
            <div className="hidden md:flex items-center justify-center flex-grow">
              <NavLinks />
            </div>
            <div className="hidden md:flex items-center">
              <AuthButtons user={user} handleLogout={handleLogout} />
            </div>
            <div className="md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              >
                <span className="sr-only">Open main menu</span>
                {isMenuOpen ? <FaTimes className="h-6 w-6" /> : <FaBars className="h-6 w-6" />}
              </button>
            </div>
          </div>
        </div>

        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <NavLinks mobile closeMenu={closeMenu} />
              <AuthButtons user={user} handleLogout={handleLogout} mobile closeMenu={closeMenu} />
            </div>
          </div>
        )}
      </nav>
      <div className="h-16"></div> {/* Spacer to prevent content from being hidden under the fixed navbar */}
    </>
  );
};

const NavLinks = ({ mobile, closeMenu }) => {
  const links = [
    { to: "/", label: "Home" },
    { to: "/pricing", label: "Pricing" },
    { to: "/about", label: "About Us" },
    { to: "/faqs", label: "FAQs" },
    { to: "/contact", label: "Contact" },
  ];

  const linkClasses = mobile
    ? "block px-3 py-2 rounded-md text-base font-medium hover:bg-[#3c2657] hover:text-white transition-colors duration-200"
    : "px-3 py-2 rounded-md text-sm font-medium hover:bg-[#3c2657] hover:text-white transition-colors duration-200";

  return (
    <div className={mobile ? "" : "flex space-x-4"}>
      {links.map((link) => (
        <Link
          key={link.to}
          to={link.to}
          className={linkClasses}
          onClick={mobile ? closeMenu : undefined}
        >
          {link.label}
        </Link>
      ))}
    </div>
  );
};

const AuthButtons = ({ user, handleLogout, mobile, closeMenu }) => {
  const buttonClasses = mobile
    ? "block w-full text-left px-3 py-2 rounded-md text-base font-medium hover:bg-[#3c2657] hover:text-white transition-colors duration-200"
    : "px-3 py-2 rounded-md text-sm font-medium hover:bg-[#3c2657] hover:text-white transition-colors duration-200";

  const signUpClasses = mobile
    ? "block w-full text-left px-3 py-2 rounded-md text-base font-medium border border-white hover:bg-white hover:text-[#2c183f] transition-colors duration-200"
    : "px-3 py-2 rounded-md text-sm font-medium border border-white hover:bg-white hover:text-[#2c183f] transition-colors duration-200";

  if (user) {
    return (
      <>
        <Link to="/dashboard" className={buttonClasses} onClick={mobile ? closeMenu : undefined}>
          <FaUser className="inline mr-2" />Dashboard
        </Link>
        <button onClick={handleLogout} className={buttonClasses}>
          Logout
        </button>
      </>
    );
  }

  return (
    <Link 
      to="/signup" 
      className={signUpClasses}
      onClick={mobile ? closeMenu : undefined}
    >
      Sign up
    </Link>
  );
};

export default Navbar;