import React from 'react';
import { Link } from 'react-router-dom';

const AlternatingSection = ({ title, content, imageSrc, isReversed }) => {
  return (
    <div className={`py-16 ${isReversed ? 'bg-white' : 'bg-gray-100'}`}>
      <div className="container mx-auto px-4">
        <div className={`flex flex-col ${isReversed ? 'md:flex-row-reverse' : 'md:flex-row'} items-center`}>
          <div className="md:w-1/2 mb-8 md:mb-0 md:px-8">
            <h2 className="text-4xl font-bold mb-4 text-gray-800">{title}</h2>
            <p className="mb-6 text-gray-600">{content}</p>
            <Link to="/check-it-out" className="text-teal-500 hover:text-teal-600 transition duration-300 inline-flex items-center">
              Check it out 
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </Link>
          </div>
          <div className="md:w-1/2">
            <img src={imageSrc} alt="" className="w-full h-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlternatingSection;