import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import LoginForm from '../components/LoginForm';
import FullPageLoader from '../components/FullPageLoader';

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (credentials) => {
    setIsLoading(true);
    try {
      const success = await login(credentials.emailOrUsername, credentials.password);
      if (success) {
        navigate('/dashboard');
      } else {
        // Handle login failure
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 via-purple-800 to-pink-800 flex justify-center items-center">
      <div className="smooth-landing">
        <LoginForm onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default Login;