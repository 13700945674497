import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const AdvancedMarketing = ({ isReversed }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const images = [
    "https://via.placeholder.com/500x300?text=Multi-Language+Support",
    "https://via.placeholder.com/500x300?text=Real-Time+Collaboration",
    "https://via.placeholder.com/500x300?text=Advanced+Analytics"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="bg-gradient-to-br from-purple-900 via-purple-800 to-pink-800 py-16 text-white">
      <div className="container mx-auto px-4">
        <div className={`flex flex-col ${isReversed ? 'md:flex-row-reverse' : 'md:flex-row'} items-center`}>
          <div className="md:w-1/2 mb-8 md:mb-0 md:px-8">
            <h2 className="text-4xl font-bold mb-4">Enhance Your Development Ecosystem</h2>
            <p className="mb-6 text-gray-300">
              NeXterChat API goes beyond basic compilation. We offer a comprehensive 
              suite of tools designed to enhance your entire development ecosystem. 
              With multi-language support, real-time collaboration features, and 
              advanced analytics, we empower teams to work more efficiently and 
              gain deeper insights into their code performance.
            </p>
            <Link to="/ecosystem" className="text-teal-300 hover:text-teal-200 transition duration-300 inline-flex items-center">
              Explore Our Ecosystem 
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </Link>
          </div>
          <div className="md:w-1/2 relative">
            <img src={images[activeSlide]} alt="NeXterChat API Ecosystem Features" className="w-full h-auto rounded-lg shadow-lg" />
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex">
              {images.map((_, index) => (
                <button
                  key={index}
                  className={`w-2 h-2 rounded-full mx-1 ${index === activeSlide ? 'bg-teal-500' : 'bg-gray-400'}`}
                  onClick={() => setActiveSlide(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedMarketing;
