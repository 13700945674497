import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-[#111827] text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <div className="flex items-center mb-4">
              <div className="w-8 h-8 bg-blue-500 rounded-full mr-2"></div>
              <span className="font-bold text-xl">NeXterChat API</span>
            </div>
            <p className="text-gray-400 mb-4">
              Empowering developers with powerful compilation tools for seamless code execution and optimization.
            </p>
          </div>
          <div className="grid grid-cols-2 gap-8">
            <div>
              <h4 className="text-gray-300 font-semibold mb-3 uppercase text-sm">Company</h4>
              <ul className="space-y-2">
                <li><Link to="/about" className="text-blue-400 hover:text-blue-300">About</Link></li>
                <li><Link to="/features" className="text-blue-400 hover:text-blue-300">Features</Link></li>
                <li><Link to="/documentation" className="text-blue-400 hover:text-blue-300">Documentation</Link></li>
                <li><Link to="/pricing" className="text-blue-400 hover:text-blue-300">Pricing</Link></li>
              </ul>
            </div>
            <div>
              <h4 className="text-gray-300 font-semibold mb-3 uppercase text-sm">Help</h4>
              <ul className="space-y-2">
                <li><Link to="/support" className="text-blue-400 hover:text-blue-300">API Support</Link></li>
                <li><Link to="/integration" className="text-blue-400 hover:text-blue-300">Integration Guide</Link></li>
                <li><Link to="/terms-and-conditions" className="text-blue-400 hover:text-blue-300">Terms & Conditions</Link></li>
                <li><Link to="/privacy-policy" className="text-blue-400 hover:text-blue-300">Privacy Policy</Link></li>
              </ul>
            </div>
          </div>
          <div>
            <h4 className="text-gray-300 font-semibold mb-3 uppercase text-sm">Newsletter</h4>
            <form className="mt-4">
              <input
                type="email"
                placeholder="Enter your email address"
                className="w-full p-2 bg-gray-700 rounded-md text-white mb-2"
              />
              <button
                type="submit"
                className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-300"
              >
                Subscribe Now
              </button>
            </form>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-center">
          <p className="text-sm text-gray-400">
            © Copyright {new Date().getFullYear()}, All Rights Reserved by NeXterChat
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;