import React from 'react';
import { Link } from 'react-router-dom';
import Features from '../components/Features';
import AdvancedMarketing from '../components/AdvancedMarketing';
import AlternatingSection from '../components/AlternatingSection';
import TurnSection from '../components/TurnSection.js';

const Home = () => {
  return (
    <div className="bg-[#2c183f]">
      <TurnSection className="-mt-px">
        <div className="container mx-auto px-4 py-16 text-white">
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mb-8 md:mb-0">
              <h1 className="text-6xl font-bold mb-4">Experience the NeXt Level of Compilation with NeXterChat API</h1>
              <p className="mb-6 text-lg">
                Empower your applications with our cutting-edge compiler API. 
                Seamlessly execute code, optimize performance, and accelerate 
                your development process. Whether you're building educational 
                platforms, coding challenges, or innovative IDEs, NeXterChat API 
                provides the tools you need to succeed.
              </p>
              <Link 
                to="/signup" 
                className="bg-pink-600 text-white px-8 py-3 rounded-full font-semibold hover:bg-pink-700 transition duration-300 inline-flex items-center group"
              >
                Start for Free
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-2 transform group-hover:translate-x-1 transition-transform duration-200" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </TurnSection>
      
      <TurnSection>
        <Features />
      </TurnSection>
      
      <TurnSection>
        <AdvancedMarketing isReversed={false} />
      </TurnSection>
      
      <TurnSection>
        <AlternatingSection 
          title="Secure and Scalable Compilation Solutions"
          content="At NeXterChat API, we prioritize the security and scalability of your code execution. Our privately owned information and cyber security measures ensure that your intellectual property remains protected. With advanced containerization and sandboxing techniques, we provide a safe environment for compiling and running code, allowing you to focus on innovation without compromising on security."
          imageSrc="/path-to-your-actual-second-image.png"
          isReversed={true}
        />
      </TurnSection>
      
      {/* Temporarily comment out other sections for testing
      <TurnSection>
        <AlternatingSection 
          title="Advanced Code Execution and Optimization"
          content="Experience the power of our state-of-the-art compiler technology. NeXterChat API offers the most advanced code execution and optimization services in the industry. From real-time syntax checking to intelligent code suggestions, our API enhances your development workflow. Leverage our powerful compilation tools to build faster, more efficient applications and stay ahead in the rapidly evolving world of software development."
          imageSrc="/path-to-your-actual-first-image.png"
          isReversed={false}
        />
      </TurnSection>
      */}
    </div>
  );
};

export default Home;