import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import API from '../api/config';
import LoadingDots from './LoadingDots';

const SignUpForm = () => {
  const [userData, setUserData] = useState({ username: '', email: '', password: '' });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await API.post('/users/register', userData);
      localStorage.setItem('token', response.data.token);
      navigate('/'); // Redirect to home page after successful registration
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred during registration');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="form-container bg-gradient-to-br from-purple-900 via-purple-800 to-pink-800 p-8 rounded-lg shadow-lg max-w-md w-full">
      <h2 className="text-3xl font-bold text-white mb-6 text-center">Sign Up</h2>
      {error && <p className="text-red-500 text-center mb-4">{error}</p>}
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username" className="text-sm font-medium text-gray-300 block mb-2">Username</label>
          <input 
            type="text" 
            name="username" 
            id="username" 
            className="bg-gray-700 text-white w-full px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600" 
            onChange={handleChange}
            value={userData.username}
            required 
          />
        </div>
        <div>
          <label htmlFor="email" className="text-sm font-medium text-gray-300 block mb-2">Email</label>
          <input 
            type="email" 
            name="email" 
            id="email" 
            className="bg-gray-700 text-white w-full px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600" 
            onChange={handleChange}
            value={userData.email}
            required 
          />
        </div>
        <div>
          <label htmlFor="password" className="text-sm font-medium text-gray-300 block mb-2">Password</label>
          <input 
            type="password" 
            name="password" 
            id="password" 
            className="bg-gray-700 text-white w-full px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600" 
            onChange={handleChange}
            value={userData.password}
            required 
          />
        </div>
        <button 
          type="submit" 
          className="w-full bg-pink-600 text-white py-2 px-4 rounded-md hover:bg-pink-700 transition duration-300 flex items-center justify-center"
          disabled={isLoading}
        >
          {isLoading ? <LoadingDots /> : 'Create Account'}
        </button>
      </form>
      <p className="mt-8 text-center text-sm text-gray-300">
        Already have an account?
        <Link to="/login" className="font-medium text-pink-400 hover:text-pink-300 ml-1">Log in</Link>
      </p>
    </div>
  );
};

export default SignUpForm;