import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <div className="space-y-4">
        <section>
          <h2 className="text-2xl font-semibold mb-2">1. Information Collection</h2>
          <p>We collect information necessary to provide and improve our API service, including account details, usage statistics, and payment information.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">2. Use of Information</h2>
          <p>We use collected information to provide, maintain, and improve our API service, as well as to communicate with you about your account and service updates.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">3. Data Security</h2>
          <p>We implement industry-standard security measures to protect your data. However, no method of transmission over the Internet is 100% secure.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">4. Third-Party Services</h2>
          <p>We may use third-party services for payment processing and analytics. These services have their own privacy policies.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">5. Data Retention</h2>
          <p>We retain your data for as long as your account is active or as needed to provide you services. You may request deletion of your data at any time.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">6. Changes to Privacy Policy</h2>
          <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;