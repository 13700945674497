import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
      <div className="space-y-4">
        <section>
          <h2 className="text-2xl font-semibold mb-2">1. Subscription and Usage</h2>
          <p>By using the NeXterChat API, you agree to abide by these terms and conditions. Our service is provided on a subscription basis, with different tiers offering varying levels of access and features.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">2. API Usage and Rate Limits</h2>
          <p>Each subscription tier has specific rate limits. Exceeding these limits may result in temporary suspension of service. We reserve the right to monitor and limit excessive usage.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">3. Payment and Billing</h2>
          <p>Subscriptions are billed on a monthly or annual basis. You are responsible for all charges incurred under your account.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">4. Cancellation and Refunds</h2>
          <p>You may cancel your subscription at any time. Refunds are provided on a pro-rata basis for annual subscriptions only.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">5. Service Modifications</h2>
          <p>We reserve the right to modify, suspend, or discontinue any part of the API service at any time.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">6. Liability</h2>
          <p>NeXterChat API is provided "as is" without warranty of any kind. We are not liable for any damages arising from the use of our service.</p>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;