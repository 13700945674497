import React from 'react';

const AboutUs = () => {
  return (
    <div className="container mx-auto px-4 py-16 text-white">
      <h1 className="text-4xl font-bold mb-8 text-center text-gradient bg-gradient-to-r from-pink-500 to-purple-600 bg-clip-text text-transparent">About NeXterChat API</h1>
      <p className="mb-4 text-lg">NeXterChat API is at the forefront of compiler technology, providing developers with powerful tools to streamline code execution and optimization. Our team of expert engineers is dedicated to revolutionizing the way developers work with code.</p>
      <p className="mb-4 text-lg">With a deep understanding of compilation processes and a passion for innovation, we're committed to delivering cutting-edge API solutions that enhance development workflows and boost productivity.</p>
      <h2 className="text-2xl font-semibold mb-4 text-pink-500">Our Mission</h2>
      <p className="text-lg">To empower developers and businesses with advanced compilation tools that accelerate development, improve code quality, and drive innovation in software creation.</p>
      <h2 className="text-2xl font-semibold mb-4 mt-8 text-pink-500">Our Core Principles</h2>
      <ul className="list-disc list-inside text-lg">
        <li>Innovation: Continuously advancing compiler technology to meet evolving developer needs.</li>
        <li>Performance: Delivering lightning-fast code execution and optimization.</li>
        <li>Reliability: Ensuring consistent and dependable API performance.</li>
        <li>Developer-Centric: Focusing on creating tools that genuinely enhance the development experience.</li>
        <li>Security: Prioritizing the protection of code and data in all our services.</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-4 mt-8 text-pink-500">Our Expertise</h2>
      <p className="text-lg">With years of experience in compiler design, cloud computing, and API development, our team brings a wealth of knowledge to every aspect of NeXterChat API. We're not just service providers; we're your partners in pushing the boundaries of what's possible in code execution and optimization.</p>
    </div>
  );
};

export default AboutUs;