import React from 'react';

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white rounded-lg p-6 shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-105 flex flex-col items-center text-center">
    <div className="text-teal-500 text-4xl mb-4">{icon}</div>
    <h3 className="text-gray-800 text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Features = () => {
  const features = [
    { icon: '</>', title: 'Clean Code', description: 'Our compiler API generates clean, optimized code for better performance and readability.' },
    { icon: '💻', title: 'Multi-Language Support', description: 'Compile and run code in various programming languages with our versatile API.' },
    { icon: '🕒', title: '24/7 Service', description: 'Our API is available round the clock, allowing you to compile and run code anytime.' },
    { icon: '💖', title: 'Value for Money', description: 'Get high-quality compilation services at competitive prices, maximizing your investment.' },
    { icon: '⚡', title: 'Faster Response', description: 'Experience quick compilation times with our optimized infrastructure and algorithms.' },
    { icon: '☁️', title: 'Cloud Support', description: 'Leverage cloud-based compilation, eliminating the need for local setup and maintenance.' },
  ];

  return (
    <div className="bg-gradient-to-br from-purple-900 via-purple-800 to-pink-800 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-white text-center mb-12">Something You Need To Know</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;